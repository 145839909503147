/* You can add global styles to this file, and also import other style files */

@import '~@ctrl/ngx-emoji-mart/picker';


html,
body {
    margin: 0;
    font-size: 10px;
    font-family: 'Poppins', Regular;
    height: 100%;
}

* {
    outline: none;
    margin: 0px;
    box-sizing: border-box;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #b9b2b2;
}
.lineClamp1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.lineClamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.lineClamp3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.lineClamp4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.lineClamp5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.lineClamp7 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}

.lineClamp8 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
}

/* CK Editor */

.ck-editor__editable_inline {
    min-height: 25rem;
    padding: 0 11.7px !important;
    font-size: 15px;
    overflow-y: auto;
    /* max-height: 38rem; */
}

.ck-placeholder {
    font-size: 15px;
}

* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.croppr-container * {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.croppr-container img {
    vertical-align: middle;
    max-width: 100%;
}

.croppr {
    position: relative;
    display: inline-block;
}

.croppr img {
    max-height: 30rem;
}

.croppr-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: crosshair;
}

.croppr-region {
    border: 1px dashed rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 3;
    cursor: move;
    top: 0;
}

.croppr-imageClipped {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
}

.croppr-handle {
    border: 1px solid black;
    background-color: white;
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: 4;
    top: 0;
}


/* The switch - the box around the slider */

.switch {
    position: relative;
    margin-top: auto;
    display: inline-block;
    width: 45px;
    height: 19px;
}


/* Hide default HTML checkbox */

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .8s;
    transition: .8s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 15px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .8s;
    transition: .8s;
}

input:checked+.slider {
    background-color: #73AF00;
}

input:focus+.slider {
    box-shadow: 0 0 1px #006BFF;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

view-profile{
    box-shadow: 0px 3px 6px #17171729;
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media print {
    #printSection {
        padding: 1rem
    }
}



table {
    width: 100%;
    text-align: left;
    background-color: #FFFFFF;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #E4E4E4;
    border-spacing: 0;
}

td {
    padding: 16px;
    color: #222222;
}

button {
    font-family: 'Poppins', Regular;
}

::placeholder {
    font-family: 'Poppins', Regular;
}

textarea {
    font-family: 'Poppins', Regular;
}


/* Hide scrollbar for Chrome, Safari and Opera */


.no_scroll_bar::-webkit-scrollbar {
    /* display: none; */
  width: 10px;
 
}
.no_scroll_bar::-webkit-scrollbar-thumb{
    background-color:transparent;
    border-radius:5px
    
}
.no_scroll_bar::-webkit-scrollbar-thumb:hover{
    background-color:#e4e4e4;
    border-radius:5px
    
}
.no_scroll_bar::-webkit-scrollbar-track{
    background-color: transparent;
}


.mail_scroll_bar::-webkit-scrollbar {
  width: 5px;
 height: 5px;
}
.mail_scroll_bar::-webkit-scrollbar-thumb{
    background-color: black;
}
.mail_scroll_bar::-webkit-scrollbar-track{
    background-color: white;
    border-radius: 5px;
}

/* Hide scrollbar for IE, Edge and Firefox */

.no_scroll_bar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.no_scroll::-webkit-scrollbar{
    display: none;
}

.coolQRCode img {
    height: 240px;
    width: 240px;
}

/* .coolQRCode {
    height: 100%;
    width: 100%;
} */


/* price Filter */

.price-field {
    position: relative;
    width: 80%;
    height: 20px;
    box-sizing: border-box;
    padding-top: 15px;
    padding-left: 0px;
}

.price-field input[type=range] {
    position: absolute;
    left: 0;
}


/* Reset style for input range */

.price-field input[type=range] {
    width: 100%;
    height: 6px;
    outline: 0;
    box-sizing: border-box;
    border-radius: 5px;
    pointer-events: none;
    background-color: darkblue;
    -webkit-appearance: none;
}

.price-field input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.price-field input[type=range]:active,
.price-field input[type=range]:focus {
    outline: 0;
}


/* Style toddler input range */

.price-field input[type=range]::-webkit-slider-thumb {
    /* WebKit/Blink */
    position: relative;
    -webkit-appearance: none;
    margin: 0;
    border: 0;
    outline: 0;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    margin-top: -4px;
    background-color: rgb(156, 148, 148);
    cursor: pointer;
    cursor: pointer;
    pointer-events: all;
    z-index: 100;
}

.price-field input[type=range]:hover::-webkit-slider-thumb {
    box-shadow: 0 0 0 0.5px #242424;
    transition-duration: 0.3s;
}

.price-field input[type=range]:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 0.5px #242424;
    transition-duration: 0.3s;
}

.slide-track {
    display: flex;
    width: calc(250px * 18);
    animation: scroll 40s linear infinite;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 9));
    }
}

.slide {
    height: 200;
    width: 250;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
}

.intro {
    height: 100vh;
    position: relative;
}

#slider {
    position: absolute;
    height: 100%;
    width: 400%;
    top: 0;
    left: 0;
    overflow: hidden;
    animation: slider 10s infinite;
}


/* #slider figure {
    position: relative;
    width: 500%;
    margin: 0;
    left: 0;
    animation: 10s slider infinite;
} */

#slider div {
    position: relative;
    width: 25%;
    height: 100%;
    float: left;
    overflow: hidden;
}

@keyframes slider {
    0% {
        left: 0;
    }

    20% {
        left: 0;
    }

    25% {
        left: -100%;
    }

    45% {
        left: -100%
    }

    50% {
        left: -200%;
    }

    70% {
        left: -200%;
    }

    75% {
        left: -300%;
    }

    95% {
        left: -300%;
    }

    100% {
        left: -300%
    }

    100% {
        left: -300%
    }
}

.circlePercent {
    position: relative;
    top: 50px;
    left: 0px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: rgb(221, 213, 213);
}

.circlePercent:before,
.circlePercent>.progressEnd {
    position: absolute;
    z-index: 3;
    top: 3px;
    left: 60px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: rgb(105, 194, 132);
    -ms-transform-origin: 3px 46px;
    transform-origin: 3px 46px;
    content: "";
}

.circlePercent:after,
.circlePercent>.progress {
    position: absolute;
    -ms-transform-origin: 48px 48px;
    transform-origin: 60px 62px;
    z-index: 0;
    top: 4px;
    left: 5px;
    width: 60px;
    height: 122px;
    border-radius: 48px 0 0 48px;
    background: rgb(212, 212, 210);
    content: "";
}

.circlePercent.fiftyPlus:after {
    background: rgb(105, 194, 132);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.circlePercent>.progress.progress {
    background: rgb(105, 194, 132);
}

.circlePercent>.counter {
    position: absolute;
    box-sizing: border-box;
    z-index: 2;
    width: 133px;
    height: 134px;
    margin-top: -1px;
    margin-left: -1px;
    border-radius: 50%;
    border: 6px solid rgb(255, 255, 255);
}

.circlePercent>.counter:before {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0%;
    margin-top: -13px;
    width: 100%;
    height: 26px;
    font-size: 26px;
    line-height: 26px;
    font-family: sans-serif;
    text-align: center;
    color: rgb(105, 194, 132);
    content: attr(data-percent) "%";
}

.circlePercent>.counter:after {
    position: absolute;
    width: 115px;
    height: 115px;
    top: 3.5px;
    left: 3px;
    border-radius: 50%;
    background: rgb(255, 254, 254);
    content: "";
}

.circlePercent>.counter[data-percent="100"] {
    background: rgb(105, 194, 132);
}

.grid:after {
    content: '';
    flex: auto;
}


.box:hover {
    box-shadow:4px 4px 6px 4px #f1f1f1; 
  }


/* flip card */


/* label { */


/* -webkit-perspective: 1000px;
    perspective: 1000px; */


/* -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    display: block; */


/* width: 350px; */


/* height: 18rem;
    margin-top: 40px; */


/* position: absolute;
    left: 0%;
    top: 0%;
    /* -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer; */


/* } */

.card {
    /* position: relative; */
    width: 350px;
    height: 18rem;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 600ms;
    transition: all 600ms;
    z-index: 20;
}

.card div {
    position: absolute;
    width: 350px;
    height: 18rem;
    background: #FFF;
    text-align: center;
    line-height: 200px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 2px;
}

.card .back {
    background: #222;
    color: #FFF;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    border-radius: 0.4rem;
}

label:hover .card {
    -webkit-transform: rotateY(20deg);
    transform: rotateY(20deg);
    box-shadow: 0 20px 20px rgba(50, 50, 50, .2);
}


/*
input {
    display: none;
} */

:checked+.card {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
}

label:hover :checked+.card {
    transform: rotateY(160deg);
    -webkit-transform: rotateY(160deg);
    box-shadow: 0 20px 20px rgba(255, 255, 255, .2);
}

.mySlides {
    display: none;
}

.radio__input {
    display: flex;
}

.radio__input input {
    opacity: 0;
    width: 0;
    height: 0;
}

.radio__control {
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.1em solid currentColor;
}

.radio__input input:checked+.radio__control {
    background: radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%);
}

.post_image {
    border-radius: 0;
}

.post_image:nth-child(1) {
    border-radius: 6px 0px 0px 0px;
}

.post_image:nth-child(3) {
    border-radius: 0px 6px 0px 0px;
}

.post_image:nth-child(7) {
    border-radius: 0px 0px 0px 6px;
}

.post_image:nth-child(9) {
    border-radius: 0px 0px 6px 0px;
}

.dark-mode {
    background-color: #535458;
    color: rgb(255, 255, 255);
}

/* .theme-light {
    --color-primary: #0060df;
    --color-secondary: #fbfbfe;
    --color-accent: #fd6f53;
    --font-color: #000000;
 }
 .theme-dark {
    --color-primary: #17ed90;
    --color-secondary: #243133;
    --color-accent: #12cdea;
    --font-color: #ffffff;
 } */
.conversation {
    color: #FFBA08;
}

.play-audio {
    background: #b2b2b2;
}

.play-audio1 {
    background: #f35325;
}

.play-audio2 {
    background: #05a6f0;
}

.box-shadow {
    box-shadow: 0px 3px 6px #1717171A;
}

.box-shadow1:hover {
    box-shadow: 0px 3px 6px #1717171A;
}

.context-hover:hover {
    background: #F5F5F5;
}

.fieldset legend {
    margin-right: 200px;
}

.help-bold {
    font-weight: bold;
}

.fw-6 {
    font-weight: 600;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
}

.help-shadow:hover {
    box-shadow: 0px 3px 6px #17171729;
}


/* 2 way in css */
.mesbro-central:hover{
    background: #F5F5F5;
    border-radius: 6px;
}
.org:hover{
    background: #EEF5FB;
    border-radius: 6px;
}





/* editor tools */
.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

input[id="cb1"]+label {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #05A6F0 !important;
    border-radius: 4px;
    cursor: pointer;
}

input[id="cb1"]:checked+label:after {
    position: relative;
    top: -4px;
    left: 2px;
    content: "\2714";
    font-size: 14px;
}

input[id="cb1"] {
    display: none;
    border: 1px solid #e4e4e4 !important;
}
input[id="check"]{
    border: 1px solid red;
}

.mail_list:hover {
    background-color: #EEF5FB !important;
}

@media screen and (min-width: 800px) and (max-width:1700px) {
    .mail-header {
        display: none;
    }
}

.container {
    position: relative;

}


.overshow {
    position: absolute;
    top: 50px;
    left: 22%;
    opacity: 0;

}

.container:hover .overshow {
    opacity: 1;
}

/* transform */

img.orie-2 {
    transform: rotate(90deg) translate(70%, -121%) scaleX(-0.69);
    transform-origin: top left;
    filter: FlipH;
}

img.orie-3 {
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
}

img.orie-4 {
    transform: rotate(-90deg) translate(-1%, 26%) scaleX(-0.69);
    transform-origin: top left;
    filter: FlipH;
}

/* navbar */


.TopMenuBar>ul {
    display: block;
    width: 180px;
}

.TopMenuBar>ul, .dropdown-menu>ul, .sub-dropdown-menu>ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.TopMenuBar>li {
    display: inline;
}

.TopMenuBar a, .dropdown-menu a, .sub-dropdown-menu a {
    color: white;
    text-decoration: none;
    display: block
}

/* Applys to all links under class TopMenuBar on hover */
.TopMenuBar a:hover {
    background-color: #FFF;
    color: black;
}


.dropdown-menu, .sub-dropdown-menu {
    display: none;
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 1;

}

/* Applys to all links under class TopMenuBar */
.dropdown-menu a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.sub-dropdown:hover .sub-dropdown-menu {
    display: table;
}



.SideMenuBar {
    background-color: orange;
    margin-left: 20px;
    margin-top: 0;
    display: flex;
    height: 100vh;
    float: left;
}

.SideMenuBar ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.SideMenuBar li a {
    color: white;
    text-decoration: none;
    padding: 20px;
    display: list-item;
}

.SideMenuBar li a:hover {
    background-color: #fcbf7e;
    color: black;
}

main {
    padding-left: 10px;
    display: table
}

h1 {
    text-align: center;
}

.sub-dropdown {
    position: relative;
}

.sub-dropdown-menu {
    left: 100%;
    top: 0;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-normal {
    font-weight: normal;
}
.center{
    text-align: center;
}
.payment-shadow{
    box-shadow: 0px 3px 6px #17171729;
}
.tax:hover{
    background: #EEF5FB;
    border-radius: 6px;
}
.Edit:hover{
    background: #EEF5FB !important;
}

.height-ellipsis {
    height: 57 px;
    display: block;
    display: -webkit-box;
    /* max-width: 100%; */
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.compare {
    white-space: nowrap;
    width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.brand {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rating-shadow {
    box-shadow: 0px 3px 6px #17171729;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.box:hover {
    box-shadow: 0px 3px 6px #17171729;
}

.hide {
    display: none;
    position: relative;
}

.myDIV:hover+.hide {
    display: block;
    color: fff;
}

.search-box {
    height: 30px;
    width: 100px;
}

/* .search-box input {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background-color: #FFF;
    font-size: 12px;
    border-radius: 50px;
    padding: 0 20px 0 20px;
} */
/* .search-box .cancel-btn {
    position: absolute;
    top: 50%;
    right: -40px;
    transform: translateY(-50%);
    color: #f35325;
    font-size: 25px;
    cursor: pointer;
} */
.search-bar {
    border: 0.5px solid #333;
    display: flex;
}

/* .search-bar input{
    
} */



.container {
    /* position: absolute; */
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
}

.container .search {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 20px;
    /* background: crimson; */
    border-radius: 0.6;
    /* border-bottom: 1px solid rgb(228, 228, 228); */
    transition: all 1s;
    z-index: 4;
    /* box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4); */
}

.container .search:hover {
    cursor: pointer;
}
.top-brand:hover{
    box-shadow: 0px 3px 6px #17171729;
}

.container .search::before {
    content: "";
    position: absolute;
    margin: auto;
    /* top: 22px; */
    top: 15px;

    right: 0;
    bottom: 0;
    left: 16px;
    width: 11px;
    height: 2px;
    background: #05A6F0;
    transform: rotate(45deg);
    transition: all 0.5s;
}

.container .search::after {
    content: "";
    position: absolute;
    margin: auto;
    top: -5px;
    right: 0;
    bottom: 0;
    /* left: -5px;
    width: 20px;
    height: 20px; */
    left: -1px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #05A6F0;
    transition: all 0.5s;
}

.container input {
    /* font-family: 'Inconsolata', monospace; */
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    /* left: -10px; */
    /* width: 50px;
    height: 50px; */
    width: 20px;
    height: 20px;
    outline: none;
    border: none;
    /* background: crimson; */
    background: #FFFFFF;
    color: #333;
    /* text-shadow: 0 0 10px crimson; */
    /* padding: 0 80px 0 20px; */
    /* border-radius: 30px; */
    /* box-shadow: 0 0 25px 0 crimson, 0 20px 25px 0 rgba(0, 0, 0, 0.2); */
    transition: all 1s;
    opacity: 0;
    z-index: 5;
    margin-right: 13px;
    /* font-weight: bolder; */
    /* letter-spacing: 0.1em; */
    /* border: 1px solid; */
}
.lineClamp-390 {
    overflow: hidden;
    width: 390px;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-orient: vertical;
}

.container input:hover {
    cursor: pointer;
}

.container input:focus {
    width: 401px;
    /* width: 100px; */

    opacity: 1;
    cursor: text;

}

.container input:focus~.search {
    /* right: -250px; */
    /* right: -50px; */
    left: -10px;
    /* background: #151515; */
    /* background: #000; */
    background: transparent;

    z-index: 6;
    margin-left: 12px;
}

.container input:focus~.search::before {
    top: 0;
    left: 0;
    width: 20px;
}

.container input:focus~.search::after {
    top: 0;
    left: 0;
    width: 20px;
    height: 2px;
    border: none;
    background: #05A6F0;
    border-radius: 0%;
    transform: rotate(-45deg);
}

.container input::placeholder {
    color: white;
    opacity: 0.5;
    font-weight: bolder;
}
/* 
@media screen and (min-width: 768px) and (max-width:1366px) {
    ::-webkit-input-placeholder {
        font-size: 9px;
    }
}

@media screen and (min-width: 1060) and (max-width:1366px) {
    .poonam {
        width: 50%;
    }
} */

/* faq */

.faq {
    width: 100%;
    background: #FFF;
    position: relative;
    /* border: 1px solid #E1E1E1; */
}

.faq label {
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 56px;
    padding-top: 1px;

    background-color: #fff;
    border-bottom: 1px solid #E1E1E1;
}

.faq input[type="checkbox"] {
    display: none;
}

.faq .faq-arrow {
    width: 8px;
    height: 8px;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    /* -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); */
    border-left: 2px solid #05a6f0;
    border-bottom: 2px solid #05a6f0;
    float: right;
    position: relative;
    top: -40px;
    right: 20px;
    -webkit-transform: rotate(317deg);     
    transform: rotate(317deg);
}
/* -45 degree */
.faq input[type="checkbox"]:checked+label>.faq-arrow {
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    /* -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); */
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.faq input[type="checkbox"]:checked+label {
    display: block;
    background: rgba(255, 255, 255, 255) !important;
    color: #4f7351;
    height: 100px;
    transition: height 0.8s;
    /* -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); */
}

.faq input[type='checkbox']:not(:checked)+label {
    display: block;
    transition: height 0.8s;
    height: 60px;
    /* -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); */
}

/* end faq */

.edit-comment {
    box-shadow: 0px 3px 6px #17171729;
}

.edit:hover {
    background: #F5F5F5;
}
.central:hover{
    background-color: #EEF5FB ;
}

.trader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.image1 {
    width: 100%;
    height: 400px;
}

/* .Shadow{
    box-shadow: inset 2px 2px 5px 0 #17171729;
} */
.card4{
    resize: none;
}
.Shadows{
    box-shadow: inset 0 2px 5px 0 #17171729;
}

#editor2 .ck.ck-editor{
    border: red;
    outline: none;
    width: 137rem;
}
/*
#editor2 .ck.ck-reset_all{
    border: none;
   
}
#editor2 .ck.ck-editor__main{
    border: none;
    outline: none; 
   
 
}
#editor2 .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    border: none;
   
    overflow-y: auto;
    min-height: 50rem !important;
    outline:none;  

    
}
#editor2 .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar{
    border: none;
    background-color: transparent;
    
}
#editor2.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused{
    outline: none;
    border: var(--ck-focus-ring),0 0 !important;
    box-shadow: var(--ck-inner-shadow),!important;
   
} */

/* CK Editor2 */
#editor .ck.ck-editor{
    border: none;
  outline: none;
  width: 137rem;
}
#editor .ck.ck-reset_all{
    border: none;
}
#editor .ck.ck-editor__main{
    border: none;
    outline: none; 
   
 
}

#editor .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    border: none;
   
    overflow-y: auto;
    min-height: 50rem !important;
    outline:none;  

    
}
#editor .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar{
    border: none;
    background-color: transparent;
    
}
#editor .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused{
    outline: none;
    border: var(--ck-focus-ring),0 0 !important;
    box-shadow: var(--ck-inner-shadow),!important;
   
}


 table {
    width: 100%;
    text-align: left;
    background-color: #FFFFFF;
    border-radius: 4px;
    opacity: 1;
    border:none;
    border-spacing: 0;
}

table td {
    width: 100%;
    text-align: left;
    background-color: #FFFFFF;
    border-radius: 4px;
    opacity: none;
    border:none;
    border-spacing: 0;
}
.placeHolder::placeHolder{
    color: black;
    opacity: 1;
}
/* :host .ck.ck-editor__main{
    min-height: 500rem !important;
} */


/* ck ck-editor__main{
    transform: rotate(180deg);
}


/* @media screen and (max-width: 900px) {
    .map {
        -webkit-flex-direction: column;
        flex-direction: column;
    }
}

@media screen and (max-width: 900px) {
    .map1 {
        width: 100%;
    }
}

@media screen and (max-width: 1366px) {
    .fs-9 {
        font-size: 9px !important;
    }
}

@media screen and (max-width: 1366px) {
    .fs-11 {
        font-size: 11px !important;
    }
}

@media screen and (max-width: 1366px) {
    .fs-20 {
        font-size: 20px !important;
    }
}

@media screen and (max-width: 1366px) {
    .pin-image {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width: 1366px) {
    .left-right {
        width: 32px !important;
    }
}

@media (min-width:480px) and (max-width: 1000px) {
    .divide {
        width: 100% !important;
        flex-wrap: wrap;
    }
}

@media (min-width:1000px) and (max-width: 1600px) {
    .review {
        width: 200px !important;
    }
}

@media (min-width:1000px) and (max-width: 1600px) {
    .review1 {
        width: 400px !important;
    }
}

@media (min-width:1000px) and (max-width: 1150px) {
    .one1 {
        width: 450px !important;
    }
}

@media (min-width:480px) and (max-width: 750px) {
    .one2 {
        width: 450px !important;
    }
}


@media (min-width:480px) and (max-width: 570px) {
    .enquiry {
        display: none !important;
    }
}

@media (min-width:480px) and (max-width: 750px) {
    .category-input {
        flex-wrap: wrap !important;
    }
}

@media (min-width:480px) and (max-width: 750px) {
    .enquiry1 {
        margin: 5px !important;
    }
}

@media (min-width:480px) and (max-width: 750px) {
    .enquiry2 {
        padding: 0px !important;
    }
}

@media (min-width:480px) and (max-width: 750px) {
    .enquiry3 {
        gap: 5px !important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .image-1 {
        width: 80px!important;
        height: 80px!important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .fs-14 {
        font-size: 14px!important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .fs-16 {
        font-size: 16px!important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .fs-11 {
        font-size: 11px!important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .fs-9 {
        font-size: 9px!important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .image-2 {
        min-width: 365px !important;
        max-width: 365px !important;
        min-height: 365px !important;
        max-height: 365px !important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .product1 {
        width: auto !important;
        height: 70px !important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .image-14 {
        width: 14px !important;
        height: 14px !important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .width-17 {
        width: 170px !important;
        height: 200px !important;
        margin-bottom: 0px!important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .width-11 {
        width: 110px !important;
        min-width: 0px !important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .chat-mobile{
    min-width: 225px!important;
    max-width: 225px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .mb-12{
    margin-bottom: 12px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .mb-8{
    margin-bottom: 8px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .mt-5{
    margin-top: 5px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .h-58{
    height: 58px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .mt-mb-12{
    margin-bottom: 10px!important;
    margin-top: 10px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .product-button{
    padding: 0.5px 10px!important;
    height: 25px!important;
    font-size: 10px!important;
    }
  } 
  @media  (min-width:1366px) and (max-width: 1850px){
    .mb-0{
    margin-bottom: 0px;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .max-h-100{
    max-height:100px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .max-h-auto{
    min-height:auto!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .max-h-365{
    max-height:365px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .max-h-40{
    max-height:40px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .h-35{
    height:35px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .p-12{
    padding: 12px!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .h-auto{
    height: auto!important;
    }
  }
  @media  (min-width:1366px) and (max-width: 1850px){
    .product-gap{
    gap: 10px!important;
    }
}

@media (min-width:480px) and (max-width: 1400px) {
    .header-none {
        display: none!important;
    }
}
@media (min-width:480px) and (max-width: 650px) {
    .review-wrap {
        flex-wrap: wrap!important;
        gap: 10px!important;
        justify-content: center!important;
        width: 0px!important;
    }
}
@media (min-width:480px) and (max-width: 650px) {
    .review-375 {
        width: 375px!important;
        justify-content: center;
    }
}
@media (min-width:651px) and (max-width: 850px) {
    .review-300 {
        width: 300px!important
    }
}

@media (min-width:480px) and (max-width: 650px) {
    .review-width {
        min-width: 200px!important;
        max-width: 200px!important;
    }
}
@media (min-width:480px) and (max-width: 650px) {
    .review-200 {
        min-width: 200px!important;
        max-width: 200px!important;
    }
}
@media  (min-width:1366px) and (max-width: 1850px){
    .text-area-height{
    height: 80px!important;
    }
}
@media (min-width:1366px) and (max-width: 1850px) {
    .fs-11-modal {
        font-size: 11px!important;
    }
}
@media (min-width:1366px) and (max-width: 1850px) {
    .fs-16-modal {
        font-size: 16px!important;
    }
}
@media (min-width:1366px) and (max-width: 1850px) {
    .modal-width {
        width : auto!important;
        height: 20px!important;
    }
}
@media (min-width:1366px) and (max-width: 1850px) {
    .button-modal {
        padding-top: 4px!important;
        padding-bottom: 4px!important;
        padding-left: 10px!important;
        padding-right: 10px!important;
        height: 22px!important;
        font-size: 9px;
    }
}
@media (min-width:768px) and (max-width: 1850px) {
    .fs-lavel-9 label{
        font-size: 9px!important;
    }
}

@media (min-width:1366px) and (max-width: 1850px) {
    .fs-9-1366 {
        font-size: 9px!important;
    }
}
@media (min-width:480px) and (max-width: 768px) {
    .mobile-1 {
        flex-direction: row!important;
    }
}
@media (min-width:480px) and (max-width: 768px) {
    .mobile-2 {
        grid-template-columns: none!important;
    }
}
@media (min-width:480px) and (max-width: 768px) {
    .mobile-3 {
        height: 420px !important;
        min-width: 400px!important;
        max-width: 420px!important;
    }
}
@media (min-width:480px) and (max-width: 768px) {
    .mobile-4 {
        min-width: 380px!important;
        max-width: 400px!important;
        height: auto!important;
    } 
}*/


.follow-bottom{
    place-self: end;
}
/* toogle csss */

.switch-button {
    background: #FFFFFF;
    border-radius: 30px;
    overflow: hidden;
    width: 210px;
    text-align: center;
    font-size: 13px;
    letter-spacing: 1px;
    color: #333;
    position: relative;
    padding-right: 120px;
    position: relative;
    height: 30px;
    border: 1px solid #2479AB;
}
.switch-button:before {
    content: "Unpublish";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    /* width: 100px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 3;
    pointer-events: none;
    padding-right: 10px;
}
.switch-button-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
    transform: translateX(120px);
    transition: transform 300ms linear;
}
.switch-button-checkbox + .switch-button-label {
    height: 30px;
    position: relative;
    padding: 0px 0;
    display: block;
    user-select: none;
    pointer-events: none;
}
.switch-button-checkbox + .switch-button-label:before {
    content: "";
    background: #2479AB;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    transform: translateX(0);
    transition: transform 300ms;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
    position: relative;
    line-height: 30px;
}

@media (min-width:480px) and (max-width: 600px) {
    .help-height {
        height: 100%;
    }
}
@media (min-width:480px) and (max-width: 1100px) {
    .help-content {
        width: 100%!important;
    }
}
@media (min-width:480px) and (max-width: 900px) {
    .help-content2 {
        height: 100%!important;
    }
}
@media (min-width:480px) and (max-width: 750px) {
    .help-search {
        min-width: 180px!important;
        max-width: 180px!important;
        width: 180px!important;
    }
}
@media (min-width:480px) and (max-width: 750px) {
    .help-search1 {
        min-width: 180px!important;
        max-width: 180px!important;
        width: 180px!important;
    }
}

@media (min-width:480px) and (max-width:768px){
    .privacy{
        left: 30%!important;
    }
}
  
@media (min-width:480px) and (max-width: 950px) {
    .help-height3 {
        height: 100%;
    }
}
@media (min-width:480px) and (max-width:620px){
    .search-width{
    width: 200px !important;
    }
}
@media (min-width:621px) and (max-width:768px){
    .search-width1{
    width: 350px !important;
    }
}
@media (min-width:480px) and (max-width:760px){
    .input-width{
        width: 40px !important;
    }
}
@media (min-width:480px) and (max-width:620px){
    .text-none{
        display: none !important;
    }
}
/* ul ol{
    counter-reset: my-badass-counter;
}
ul ol li::before {
    content: counter(my-badass-counter,);
    counter-increment: my-badass-counter;
    margin-right: 0px;
    font-weight: bold;
} */


/* For home page game */
@keyframes block{
    0%{left:235px;}
    100%{left:-40px;}
}
@keyframes jump{
    0%{top:150px;}
    30%{top:100px;}
    70%{top:100px;}
    100%{top:150px;}
} 
.animate{
    animation:jump 500ms infinite;
} 

.help-shadow{
    box-shadow: 0px 3px 6px #17171729;
}

/* .help-center:not(:focus) { background-color: none; color: black;} */
.help-center:focus { background-color: #EEF5FB ; color: white;}
.user:focus { background-color: #EEF5FB ; color: white;}
  



::-webkit-datetime-edit { font-family: 'Poppins' }
/* ::-webkit-datetime-edit-fields-wrapper { background: silver; } */
/* ::-webkit-datetime-edit-text { color: red; padding: 0 0.3em; }
::-webkit-datetime-edit-month-field { color: blue; }
::-webkit-datetime-edit-day-field { color: green; }
::-webkit-datetime-edit-year-field { color: purple; } */
/* ::-webkit-inner-spin-button { display: none; } */
/* ::-webkit-calendar-picker-indicator {} */

